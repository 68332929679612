import {Ref, ref} from 'vue';
import isCsr from '@/ui/utils/isCsr';

interface UILuigiBox {
  isInTestGroup: Ref<boolean>;
  mount: () => void;
}

export default function useLuigiBox (): UILuigiBox {
  const isInTestGroup = ref(isCsr && document.cookie.includes('use_luigis_box'));
  if (typeof window !== 'undefined' && window.dataLayer && typeof window.dataLayer !== 'undefined' && typeof window.dataLayer.push !== 'undefined') {
    const originalPush = window.dataLayer.push;
    window.dataLayer.push = (...args) => {
      const modifiedArgs = args.map((obj) => {
        return {
          ...obj,
          'ep.search_engine': isInTestGroup.value ? 'luigisbox' : 'default',
          ep: {search_engine: isInTestGroup.value ? 'luigisbox' : 'default'}
        };
      });
      return originalPush.apply(window.dataLayer, modifiedArgs);
    };
  }
  const mount = (): void => {
    const script = document.createElement('script');
    script.src = 'https://scripts.luigisbox.com/LBX-498123.js';
    document.body.append(script);

    if (!isInTestGroup.value) {
      if (typeof window !== 'undefined') {
        window.dataLayer.push({event: 'testyAB_wyszukiwarka_testA'});
      }

      return;
    }

    if (typeof window !== 'undefined') {
      window.dataLayer.push({event: 'testyAB_wyszukiwarka_testB'});
    }
  }

  return {
    isInTestGroup,
    mount,
  }
}
