import { UIKitComponent } from '@/ui-kit/js/types/components';

export interface UIKitElementOptions {
  tag?: keyof HTMLElementTagNameMap,
  classes?: string[];
  styles?: Record<string, unknown>;
}

class UIKitElement extends HTMLElement {
  private readonly _component: UIKitComponent;
  private readonly _options: UIKitElementOptions;
  private readonly _shadow: ShadowRoot;
  private readonly _container: HTMLElement;
  private readonly _version: string;

  constructor (component: UIKitComponent, options: UIKitElementOptions = {}) {
    super()
    this._component = component;
    this._options = options;
    this._version = window.uikit?.version ? `.${window.uikit.version}` : '';


    this._shadow = this.attachShadow({ mode: 'open' });
    this._container = this.createContainer();
    this._shadow.innerHTML += `<link rel="stylesheet" href="/assets/ui-kit${this._version}.css" />`;
    this._shadow.innerHTML += `<link rel="stylesheet" href="/assets/ui-kit/${component}${this._version}.css" />`;
    this._shadow.innerHTML += `<link rel="stylesheet" href="/assets/ui-kit/fonts/ui-icons${this._version}.css" />`;
    this._shadow.appendChild(this.container);
  }

  private createContainer (): HTMLElement {
    const element = document.createElement(this._options.tag ?? 'div');

    element.classList.add(...this._options.classes ?? []);
    Object.assign(element.style, this._options.styles ?? {})

    return element;
  }

  get component (): UIKitComponent {
    return this._component;
  }

  get shadow (): ShadowRoot {
    return this._shadow;
  }

  get container (): HTMLElement {
    return this._container;
  }

  parseAttribute<T> (name: string, type: (value: string) => T, defaultValue: T): T {
    const attribute = this.getAttribute(name);

    if (attribute === null) {
      return defaultValue;
    }

    try {
      return type(attribute);
    } catch (error) {
      console.error(`Failed to parse attribute '${name}': ${error}`);
      return defaultValue;
    }
  }

  interpolate (
    template: string,
    props: Record<string, unknown>,
  ): string {
    const names = Object.keys(props);
    const values = Object.values(props);

    // eslint-disable-next-line @typescript-eslint/no-implied-eval
    return new Function(...names, `return \`${template}\`;`)(...values) as string;
  }
}

export default UIKitElement;
